import { BsModalRef, BsModalService } from "ngx-bootstrap";
import { ModalMessageComponent } from "../../components/modal/modal-message.component";

export class BaseComponent {

  public bsModalRef: BsModalRef;

  constructor(public modalService: BsModalService) { }


  showModalMessage(message: string, time?: number) {
    const initialState = {
      message: message
    };
    this.bsModalRef = this.modalService.show(ModalMessageComponent, { initialState });

    if (time) {
      setTimeout(() => {
        this.bsModalRef.hide();
      }, time);
    }

  }

  hideModalMessage() {
    this.bsModalRef.hide();
  }
}

